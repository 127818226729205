import {PERMISSION_TYPE} from '../../../Permissions';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';
import {hasModule} from '../../shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../constants';

//**************************************************************
// If you add a ternary/inline if to a permission name or description
// then the you need to change the field to a function otherwise
// it will not work properly
//**************************************************************

const programText = () => (hasModule(MODULE_TYPES.PROGRAMS_PLUS) ? 'program' : 'project group');
const programsText = () => (hasModule(MODULE_TYPES.PROGRAMS_PLUS) ? 'programs' : 'project groups');

export const CREATE_PERSON = {
	name: 'Invite new team members',
	permissions: [PERMISSION_TYPE.PERSON_CREATE],
	description: 'Ability to invite new users to the account (this might add additional seats to your subscription).',
};

export const SCHEDULING_ACCESS = {
	name: 'View Schedule People and Schedule Projects',
	permissions: [PERMISSION_TYPE.SCHEDULING_ACCESS],
};

export const SEE_MANAGE_INSIGHTS = {
	name: 'See and manage reports',
	permissions: [
		PERMISSION_TYPE.INSIGHTS_READ,
		PERMISSION_TYPE.INSIGHTS_CREATE,
		PERMISSION_TYPE.INSIGHTS_UPDATE,
		PERMISSION_TYPE.INSIGHTS_DELETE,
	],
	description: 'Access to the reports tab and ability to create, edit & delete reports.',
};

export const CREATE_UPDATE_CLIENT = {
	name: 'Create and update client info',
	permissions: [PERMISSION_TYPE.CLIENT_CREATE, PERMISSION_TYPE.CLIENT_UPDATE],
};

// This group is part of Manage projects for now.
export const MANAGE_PROJECT_ASSIGNMENT = {
	name: 'Assign and remove people to/from projects',
	permissions: [
		PERMISSION_TYPE.PROJECT_PERSON_CREATE,
		PERMISSION_TYPE.PROJECT_PERSON_UPDATE,
		PERMISSION_TYPE.PROJECT_PERSON_DELETE,
	],
};

export const MANAGE_WORKFLOW_COLUMN = {
	name: 'Manage workflow columns',
	permissions: [
		PERMISSION_TYPE.STATUS_COLUMN_CREATE,
		PERMISSION_TYPE.STATUS_COLUMN_UPDATE,
		PERMISSION_TYPE.STATUS_COLUMN_DELETE,
	],
	description: 'Ability to create, edit & delete new workflow columns in projects.',
};

export const MANAGE_SPRINTS = {
	name: 'Manage sprints',
	permissions: [PERMISSION_TYPE.SPRINT_CREATE, PERMISSION_TYPE.SPRINT_UPDATE, PERMISSION_TYPE.SPRINT_DELETE],
	description: 'Ability to create, edit & delete sprints in projects',
};

export const MANAGE_PHASES = {
	name: 'Manage phases',
	permissions: [PERMISSION_TYPE.PHASE_CREATE, PERMISSION_TYPE.PHASE_UPDATE, PERMISSION_TYPE.PHASE_DELETE],
	description: 'Ability to create, edit & delete phases in projects.',
};

// Changed to a function to make the inline if work
export const ACCESS_ALL_PROJECTS = () => {
	return {
		name: `Access all projects${hasModule(MODULE_TYPES.PROGRAMS) ? ' and ' + programsText() : ''}`,
		permissions: [PERMISSION_TYPE.PROJECTS_READ_ALL],
		description: `Access to all projects${
			hasModule(MODULE_TYPES.PROGRAMS) ? ' and ' + programsText() : ''
		} on the account, independent of being part of the project${
			hasModule(MODULE_TYPES.PROGRAMS) ? ' or ' + programText() : ''
		} team.`,
	};
};

export const READ_ALL_PROJECTS = {
	name: 'Read data access for all projects and programs',
	permissions: [PERMISSION_TYPE.PROJECTS_READ_ALL_VIEW_ONLY],
	description:
		'Makes it possible to view data from all projects and programs, without requiring or granting edit access to all projects and programs.',
};

export const SEE_MANAGE_BASELINE = {
	name: 'See and manage Baseline',
	permissions: [
		PERMISSION_TYPE.BASELINE_READ,
		PERMISSION_TYPE.BASELINE_CREATE,
		PERMISSION_TYPE.BASELINE_UPDATE,
		PERMISSION_TYPE.BASELINE_DELETE,
	],
};

export const MANAGE_COMMENTS_ALL = {
	name: "Edit another person's comment",
	permissions: [PERMISSION_TYPE.COMMENT_UPDATE_ALL, PERMISSION_TYPE.COMMENT_DELETE_ALL],
	description: 'Ability to edit & delete other users’ comments on tasks.',
};

export const SEE_MANAGE_INVOICES = {
	name: 'See and manage Invoices',
	permissions: [
		PERMISSION_TYPE.INVOICE_READ,
		PERMISSION_TYPE.INVOICE_CREATE,
		PERMISSION_TYPE.INVOICE_UPDATE,
		PERMISSION_TYPE.INVOICE_DELETE,
	],
};

export const MANAGE_TIME_OFF = {
	name: "Manage other person's time-off",
	permissions: [PERMISSION_TYPE.MANAGE_TIME_OFF],
	description:
		'Access to the company timesheets, ' +
		'ability to view time-offs of other users and to create, ' +
		'edit, approve and reject time-off ' +
		'(only if time-off approval is enabled).',
};

export const MANAGE_TIME_REGISTRATIONS_ALL = {
	name: "Manage other person's time registrations",
	permissions: [
		PERMISSION_TYPE.TIME_REGISTRATION_CREATE_ALL,
		PERMISSION_TYPE.TIME_REGISTRATION_UPDATE_ALL,
		PERMISSION_TYPE.TIME_REGISTRATION_DELETE_ALL,
		...MANAGE_TIME_OFF.permissions,
	],

	description:
		'Access to the company timesheets, ' +
		'and ability to read, create, ' +
		'edit, and delete time entries for other users. ' +
		'Access to locking, approving, and rejecting time sheets for Project Owners and Admins (if Time Lock / Time Approval functionality is enabled).',
};

export const MANAGE_ALLOCATION = {
	name: 'Manage allocations',
	permissions: [
		PERMISSION_TYPE.ALLOCATION_CREATE,
		PERMISSION_TYPE.ALLOCATION_UPDATE,
		PERMISSION_TYPE.ALLOCATION_DELETE,
		...SCHEDULING_ACCESS.permissions,
		...SEE_MANAGE_INSIGHTS.permissions,
	],
};

export const VIEW_FINANCIAL_INFORMATION_REVENUE = {
	name: 'View financial information, revenue only',
	permissions: [
		PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
		PERMISSION_TYPE.RATE_CARDS_READ,
		PERMISSION_TYPE.RATE_CARDS_UPDATE,
		...SEE_MANAGE_BASELINE.permissions,
		...SEE_MANAGE_INVOICES.permissions,
	],
	description:
		'Access to all financial features including Baseline, Budget, ' +
		'Periods & the Project Portfolio Report - viewing only revenue',
};

export const ALL_TIMELINES_VIEW_ONLY = {
	name: 'View All Timelines Page',
	permissions: [PERMISSION_TYPE.ALL_TIMELINES_VIEW_ONLY],
	description: 'Ability to view the All Timelines page. Does not entail the ability to make changes.',
};

export const PEOPLE_SCHEDULE_VIEW_ONLY = {
	name: 'View People Schedule Page',
	permissions: [PERMISSION_TYPE.PEOPLE_SCHEDULE_VIEW_ONLY],
	description: 'Ability to view the People Schedule page. Does not entail the ability to make changes.',
};

export const DEMAND_VIEW_ONLY = {
	name: 'View Demand Page',
	permissions: [PERMISSION_TYPE.DEMAND_VIEW_ONLY],
	description: 'Ability to view the Demand page. Does not entail the ability to make changes.',
};

export const CAPACITY_OVERVIEW_VIEW_ONLY = {
	name: 'View Capacity Overview Page',
	permissions: [PERMISSION_TYPE.CAPACITY_OVERVIEW_VIEW_ONLY],
	description: 'Ability to view the Capacity Overview page. Does not entail the ability to make changes.',
};

export const MANUAL_REVENUE_RECOGNITION = {
	name: 'Manual revenue recognition',
	permissions: [PERMISSION_TYPE.MANUAL_REVENUE_RECOGNITION],
	description: 'Gives access to manual revenue recognition',
};

export const VIEW_FINANCIAL_INFORMATION = {
	name: 'View financial information',
	permissions: [PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, ...VIEW_FINANCIAL_INFORMATION_REVENUE.permissions],
	description:
		'Access to all financial features including Baseline, ' +
		'Budget, Periods & the Project Portfolio Report - viewing revenue, cost & profit',
};

export const APPROVE_ALLOCATION = {
	name: 'Approve allocations',
	permissions: [PERMISSION_TYPE.ALLOCATION_APPROVE],
	description: 'Ability to change soft allocations to hard allocations and staff placeholders.',
};

export const MANAGE_PROJECTS = {
	name: 'Manage projects',
	permissions: [
		PERMISSION_TYPE.PROJECTS_CREATE,
		PERMISSION_TYPE.PROJECTS_UPDATE,
		PERMISSION_TYPE.PROJECTS_DELETE,
		...CREATE_UPDATE_CLIENT.permissions,
		...MANAGE_PROJECT_ASSIGNMENT.permissions,
		...MANAGE_WORKFLOW_COLUMN.permissions,
		...MANAGE_ALLOCATION.permissions,
		...MANAGE_SPRINTS.permissions,
		...MANAGE_PHASES.permissions,
	],
	dependingPermissionGroups: [APPROVE_ALLOCATION],
	description:
		'Ability to create new projects and access all project settings, including creation of phases & sprints. ' +
		'Ability to manage all resources from Schedule people and Project timeline.',
};

export const PROJECT_SCHEDULING_SHARE_CREATE = {
	name: 'Share Schedule Projects',
	permissions: [PERMISSION_TYPE.PROJECT_SCHEDULING_SHARE_CREATE, ...MANAGE_PROJECTS.permissions],
	description: 'Ability to share the Schedule Projects.',
};

export const MANAGE_PROGRAMS = () => {
	return {
		name: 'Manage ' + programsText(),
		permissions: [
			PERMISSION_TYPE.PROGRAMS_CREATE,
			PERMISSION_TYPE.PROGRAMS_UPDATE,
			PERMISSION_TYPE.PROGRAMS_DELETE,
			...MANAGE_PROJECTS.permissions,
		],
		description: `Ability to create new ${programsText()}, add/remove projects to a ${programText()} and manage projects`,
	};
};

export const SEE_MANAGE_RETAINER_PERIOD = {
	name: 'See and manage retainer periods',
	permissions: [
		PERMISSION_TYPE.RETAINER_PERIOD_READ,
		PERMISSION_TYPE.RETAINER_PERIOD_CREATE,
		PERMISSION_TYPE.RETAINER_PERIOD_UPDATE,
		PERMISSION_TYPE.RETAINER_PERIOD_DELETE,
		...VIEW_FINANCIAL_INFORMATION_REVENUE.permissions,
		...MANAGE_PROJECTS.permissions,
	],
	description: 'Access to the periods tab in projects and ability to create, edit & delete periods.',
};

export const VIEW_SISENSE_DASHBOARDS = {
	name: 'View advanced analytics dashboards',
	permissions: [PERMISSION_TYPE.SISENSE_READ],
	description: 'Ability to view Advanced Analytics dashboards shared with them.',
};

export const DESIGN_SISENSE_DASHBOARDS = {
	name: 'Design advanced analytics dashboards',
	permissions: [PERMISSION_TYPE.SISENSE_READ, PERMISSION_TYPE.SISENSE_CREATE],
	description: 'Ability to design and share Advanced Analytics dashboards.',
};

// Changed to a function to make the ...spread work
export const PERMISSION_GROUPS = () => {
	return [
		...(!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) ? [VIEW_FINANCIAL_INFORMATION] : []),
		...(!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) ? [VIEW_FINANCIAL_INFORMATION_REVENUE] : []),
		...(!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) ? [MANUAL_REVENUE_RECOGNITION] : []),
		ACCESS_ALL_PROJECTS(),
		...(hasFeatureFlag('scheduling_read_only_permissions') ? [READ_ALL_PROJECTS] : []),
		PROJECT_SCHEDULING_SHARE_CREATE,
		...(hasModule(MODULE_TYPES.PROGRAMS) ? [MANAGE_PROGRAMS()] : []),
		MANAGE_PROJECTS,
		MANAGE_PHASES,
		//SEE_MANAGE_BASELINE,
		MANAGE_SPRINTS,
		MANAGE_WORKFLOW_COLUMN,
		...(hasFeatureFlag('placeholders') && hasModule(MODULE_TYPES.ALLOCATION_APPROVE) ? [APPROVE_ALLOCATION] : []),
		//MANAGE_ALLOCATION,
		//MANAGE_PROJECT_ASSIGNMENT,
		//SCHEDULING_ACCESS,
		//CREATE_UPDATE_CLIENT,
		...(!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) ? [SEE_MANAGE_INSIGHTS] : []),
		//SEE_MANAGE_INVOICES,
		SEE_MANAGE_RETAINER_PERIOD,
		...(!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED) ? [MANAGE_TIME_REGISTRATIONS_ALL] : []),
		...(hasFeatureFlag('pto_timesheet_allocation_linking') && !hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED)
			? [MANAGE_TIME_OFF]
			: []),
		CREATE_PERSON,
		MANAGE_COMMENTS_ALL,
		VIEW_SISENSE_DASHBOARDS,
		DESIGN_SISENSE_DASHBOARDS,
		...(hasFeatureFlag('scheduling_read_only_permissions')
			? [ALL_TIMELINES_VIEW_ONLY, PEOPLE_SCHEDULE_VIEW_ONLY, DEMAND_VIEW_ONLY, CAPACITY_OVERVIEW_VIEW_ONLY]
			: []),
	];
};
